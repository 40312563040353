
export const Schedule = (props) => {
    return (     
      <div id='schedule' className='text-center'>
        <div className='container'>
          <div className='section-title'>
            <h2>Расписание</h2>
          </div>
          <div id="fitbaseWidgetContainer">
            <fitbase-widget theme="light" domain="https://housefit.fitbase.io/" data-src="https://housefit.fitbase.io/vue-resources/schedule-widget/dist/js/app.js"></fitbase-widget>
          </div>
          <script src="https://housefit.fitbase.io/vue-resources/schedule-widget/init.js" defer></script>                             
        </div>
      </div>
    )
  }
  

